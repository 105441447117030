import React, { useEffect, useState } from "react";
import useQuery from "../../hooks/useQuery";
import PullToRefresh from "react-simple-pull-to-refresh";

import { useNavigate, useParams } from "react-router-dom";
import preConta from "../../shared/preConta";

import { Container, PaperForm, PaperBottom, AppBar, AppTooBar } from "./styles";
import { ContentCard } from "../Home/styles";
import formatCurrency from "../../utils/formatCurrency";
import swal from "sweetalert";
import { useAuth } from "../../hooks/auth";
import Loading from "../../shared/Loading";
import { useCesta } from "../../hooks/cesta";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ItemNaMesa from "../../components/ItemNaMesa";
import api from "../../services/api";
import { useTheme } from "../../hooks/theme";

interface iMesaProduto {
  codgrupo: string;
  codpro: string;
  descricao: string;
  enviado: string;
  id: string;
  qnt: string;
  total: string;
  unitario: string;
  datahora: string;
}

interface iMesaTotal {
  mesa: string;
  valor: string;
  servico: string;
}

interface SummaryProps {
  mesaTotal: iMesaTotal[];
}

const Summary = ({ mesaTotal }: SummaryProps) => {
  const { valor, servico } = mesaTotal[0];
  const total = (parseFloat(valor) + parseFloat(servico)).toString();
  return (
    <>
      <small>
        Sub-Total: <b>{formatCurrency(Number(valor))}</b>
      </small>
      <small>
        Serviço: <b>{formatCurrency(Number(servico))}</b>
      </small>
      <span>
        Total: <b>{formatCurrency(Number(total))}</b>
      </span>
    </>
  );
};

const Mesa: React.FC = () => {
  const { mesa: paramMesa } = useParams();
  const navigate = useNavigate();
  const { limparCestaCompra } = useCesta();
  const { signOut } = useAuth();
  const [loading] = useState<boolean>(false);
  const { db, iis } = useTheme();

  useEffect(() => {
    handleRefresh();
  }, []);

  const options = {
    params: {
      mesa: paramMesa,
      codpdv: "RES",
    },
  };

  const { data: produtos, refetch: reloadprodutos } = useQuery<iMesaProduto[]>(
    "produtos" + paramMesa,
    "mesa.ashx",
    options,
    10000,
    2500
  );

  const {
    data: mesaTotal,
    isLoading: loadtotal,
    refetch: reloadtotal,
  } = useQuery<iMesaTotal[]>(
    "mesaTotal" + paramMesa,
    "mesaTotal.ashx",
    options,
    10000,
    5000
  );

  const naoEnviados = !produtos
    ? true
    : produtos.filter((item: { enviado: string }) => item.enviado === "false")
        .length > 0;

  const handleVoltar = () => {
    if (!naoEnviados) {
      navigate("/");
    } else {
      swal({
        title: "Você precisa confirmar todos os pedidos!",
        icon: "warning",
        dangerMode: true,
      });
    }
  };

  const handlePreConta = () => {
    if (!naoEnviados) {
      preConta(iis, db, paramMesa);
      navigate("/");
    } else {
      swal({
        title: "Você precisa confirmar todos os pedidos!",
        icon: "warning",
        dangerMode: true,
      });
    }
  };

  const handleRefresh = (): Promise<void> => {
    return new Promise((res) => {
      reloadprodutos();
      reloadtotal();
      res();
    });
  };

  const handleConfirmar = async () => {
    if (!naoEnviados) {
      navigate("/");
    } else {
      const header = { "server-host": db };
      await api(iis, header)
        .get("imprimirnacozinha.ashx", {
          params: {
            codpdv: "RES",
            mesa: paramMesa,
            usuario: localStorage.getItem("@DM7Pedidos:username"),
          },
        })
        .then(() => {
          limparCestaCompra();
          handleRefresh();
        })
        .catch((err: { response: { status: number } }) => {
          if (err.response.status === 400) {
            signOut();
          } else {
            console.log("err : ", err);
          }
        });
      swal({
        title: "Pedido enviado!",
        icon: "success",
        timer: 3000,
      }).finally(() => {
        navigate("/");
      });
    }
  };

  return (
    <Container>
      <AppTooBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            aria-label="voltar"
            color="inherit"
            onClick={() => {
              handleVoltar();
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            size="large"
            aria-label="cardapio"
            color="inherit"
            onClick={() => {
              limparCestaCompra();
              navigate("/cardapio/" + paramMesa);
            }}
          >
            <RestaurantMenuIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            size="large"
            aria-label="confirmar"
            color="inherit"
            onClick={() => {
              handleConfirmar();
            }}
          >
            <MicrowaveIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            size="large"
            aria-label="pre-conta"
            color="inherit"
            onClick={() => {
              handlePreConta();
            }}
          >
            <ReceiptIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          {/* <IconButton
            size="large"
            aria-label="somar"
            color="inherit"
            onClick={() => {
              const a = produtos?.reduce((acc, cur) => {
                acc[cur.codpro] = acc[cur.codpro] || [];
                acc[cur.codpro].qtd = acc[cur.codpro].qtd + cur.qnt || 0;
                return acc;
              }, Object.create(null));

              console.log("reduce", JSON.stringify(a));
            }}
          >
            <ScienceIcon />
          </IconButton> */}
        </Toolbar>
      </AppTooBar>

      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Mesa: <b>{paramMesa}</b>
          </Typography>
        </Toolbar>
      </AppBar>

      <PaperForm elevation={6}>
        <PullToRefresh onRefresh={handleRefresh}>
          <ContentCard>
            {/* {error && <p>Algo deu errado : </p>} */}
            {loading && <Loading />}
            {!loading &&
              produtos?.map((item) => <ItemNaMesa {...item} key={item.id} />)}
          </ContentCard>
        </PullToRefresh>
      </PaperForm>
      <PaperBottom elevation={6}>
        <div>
          {loadtotal && <Loading />}
          {!loadtotal && mesaTotal && <Summary mesaTotal={mesaTotal} />}
        </div>
      </PaperBottom>
    </Container>
  );
};

export default Mesa;
