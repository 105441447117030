import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { useAuth } from "../hooks/auth";
import Home from "../pages/Home";
import Mesas from "../pages/Mesas";
import Mesa from "../pages/Mesa";
import Cardapio from "../pages/Cardapio";
import Transferencia from "../pages/Transferencia";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />              
        <Route path="/mesas" element={<RequireAuth><Mesas/></RequireAuth>} />
        <Route path="/transferencia" element={<RequireAuth><Transferencia/></RequireAuth>} />
        <Route path="/mesa/:mesa" element={<RequireAuth><Mesa /></RequireAuth>} />
        <Route path="/cardapio/:mesa" element={<RequireAuth><Cardapio /></RequireAuth>} />
      </Routes>
    </BrowserRouter>
  );
};

function RequireAuth({ children }: any) {
  const { logged } = useAuth();  
  return logged ? children : <Navigate to="/" replace />;
}

export default AppRoutes;
