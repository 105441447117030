import styled from "styled-components";
import MuiPaper from "@mui/material/Paper";

export const Container = styled.div`
  height: 100vh;
  margin-top: -20px;

  display: flex;
  flex: 1;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;

  background-color: ${(props) => props.theme.colors.toolbarcolor};
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 30px;

  > h2 {
    color: ${(props) => props.theme.colors.texttoolbarcolor} !important;
    margin-left: 7px;
  }

  > img {
    width: 200px;
    height: 200px;
    border-radius: 7px;
  }
`;

export const FormTitle = styled.div`
  > h1 {
    font-size: 25px;
    margin-bottom: 20px;
    color: ${(props) => props.theme.colors.texttoolbarcolor} !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:after {
      content: "";
      display: block;
      width: 55px;
      border-bottom: 10px solid ${(props) => props.theme.colors.warning};
    }
  }
`;

export const FooterLogin = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 100%;
  position: fixed;
  bottom: 0;
  font-size: 15px;
  background-color: ${(props) => props.theme.colors.backgroundcolor};
  color: ${(props) => props.theme.colors.texttoolbarcolor};
`;

export const FormCard = styled.main`
  width: 350px;
  height: 450px;
  padding: 30px;

  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.backgroundcolor};
  > a {
    margin-left: 10px;
    font-size: 10px;
    text-decoration: none;
    color: ${(props) => props.theme.colors.texttoolbarcolor};
  }

  @media (max-width: 340px) {
    height: 300px;
    width: 290px;
  }

  /* Galaxy Fold */
  @media (max-width: 280px) {
    height: 300px;
    width: 270px;
  }
`;

export const FormCardTitle = styled.h1`
  margin-bottom: 40px;
  font-size: 30px;
  color: ${(props) => props.theme.colors.texttoolbarcolor};

  &:after {
    content: "";
    display: block;
    width: 55px;
    border-bottom: 10px solid ${(props) => props.theme.colors.warning};
  }

  @media (max-width: 340px) {
    font-size: 23px;
  }

  /* Galaxy Fold */
  @media (max-width: 280px) {
    font-size: 20px;
  }
`;

export const ContentCard = styled.main`
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 15px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.toolbarcolor};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.backgroundcolor};
  }

  @media (max-width: 340px) {
    height: 180px;
  }
`;

export const PaperForm = styled(MuiPaper)`
  width: 100%;
  height: 430px;
  padding: 20px;
  max-width: 355px;

  border-radius: 10px !important;
  background-color: ${(props) => props.theme.colors.backgroundcolor} !important;

  > a {
    margin-left: 10px;
    font-size: 12px;
    text-decoration: none;
    color: ${(props) => props.theme.colors.texttoolbarcolor};
  }
`;
