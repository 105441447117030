import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import PullToRefresh from "react-simple-pull-to-refresh";
import Mesa, { iMesas } from "../../components/Mesa";

import { useAuth } from "../../hooks/auth";
import {
  Container,
  PaperTop,
  PaperForm,
  AppTooBar,
  PaperBottom,
} from "./styled";
import { ContentCard } from "../Home/styles";
import Loading from "../../shared/Loading";

import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Toolbar from "@mui/material/Toolbar";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import padLeft from "../../utils/padleft";

import * as STATUS from "../../utils/status";

const status = [
  STATUS.LIVRE,
  STATUS.CONSUMINDO,
  STATUS.BALCAO,
  STATUS.ENTREGA,
  STATUS.ENTREGANDO,
  STATUS.AGUARDANDO_PAGAMENTO,
];

const useMesa = () => {
  const { signOut } = useAuth();
  const showdelivery = JSON.parse(
    localStorage.getItem("@DM7Pedidos:usuario") || "0"
  )["showdelivery"];

  const { data, isLoading, refetch } = useQuery<iMesas[]>(
    "listaMesas",
    "mesas.ashx",
    {
      params: {
        codpdv: "RES",
        alltables: showdelivery,
      },
    }
  );

  return { data, isLoading, refetch, signOut };
};

const useSearch = (data: iMesas[] | null | undefined) => {
  const [items, setItems] = useState<iMesas[]>([]);
  const [digitado, setDigitado] = useState<string>("");

  useEffect(() => {
    if (data && data.length > 0) {
      setItems(data);
    }
  }, [data]);

  const onSearch = (value: string) => {
    setDigitado(value);

    if (!data) return;

    if (!value) {
      setItems(data);
    }

    const founds = data.filter((item: { mesa: string }) =>
      item.mesa.includes(value)
    );

    setItems(founds);
  };

  return { items, onSearch, digitado };
};

const Mesas = () => {
  const { data, isLoading, refetch, signOut } = useMesa();
  const { items, onSearch, digitado } = useSearch(data);
  const navigate = useNavigate();

  const [listarMesas, setListarMesas] = useState<iMesas[]>([]);

  useEffect(() => {
    setListarMesas(listarMesas);
  }, [setListarMesas, listarMesas]);

  useEffect(() => {
    setListarMesas(items);
  }, [setListarMesas, items]);

  const handleRefresh = (): Promise<void> => {
    return new Promise((res) => {
      refetch();
      res();
    });
  };

  const handleMesaDigitada = () => {
    var mesa = digitado;
    if (digitado.length < 5) {
      mesa = padLeft(digitado, 5, "0");
    }
    if (mesa === "00000") {
      setListarMesas(items);
      return;
    }

    // if (status === "(Aguardando Pagamento)") {
    //   swal({
    //     title: "Mesa [" + mesa + "] esta aguardando pagamento",
    //     text: "Solicite ao caixa a reabertura da mesa",
    //     icon: "warning",
    //     dangerMode: true,
    //   }).then(() => {});
    // } else {
    //   navigate("/mesa/" + mesa);
    // }
    navigate("/mesa/" + mesa);
  };

  return (
    <Container>
      <AppTooBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            aria-label="voltar"
            color="inherit"
            onClick={() => {
              signOut(true);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton
            size="large"
            aria-label="dividir"
            color="inherit"
            onClick={() => {
              navigate("/transferencia");
            }}
          >
            <MoveDownIcon />
          </IconButton>
        </Toolbar>
      </AppTooBar>

      <PaperTop elevation={6}>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Mesa"
          onChange={(e) => onSearch(e.target.value)}
          inputProps={{ "aria-label": "search" }}
        />
        <IconButton
          sx={{ p: "10px" }}
          aria-label="search"
          onClick={() => handleMesaDigitada()}
        >
          <SearchIcon />
        </IconButton>
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      </PaperTop>

      <PaperForm elevation={12}>
        <PullToRefresh onRefresh={handleRefresh}>
          <ContentCard>
            {/* {error && <p>Algo deu errado : </p>} */}
            {isLoading && <Loading />}
            {!isLoading &&
              listarMesas?.map((item) => <Mesa {...item} key={item.mesa} />)}
          </ContentCard>
        </PullToRefresh>
      </PaperForm>

      <PaperBottom elevation={6}>
        <Toolbar>
          {status?.map((itemStatus) => (
            <>
              <IconButton
                color="inherit"
                onClick={() => {
                  setListarMesas(
                    items.filter(
                      (item: { status: string }) =>
                        item.status === itemStatus.text
                    )
                  );
                }}
              >
                <img src={itemStatus.img} alt="imagem status da mesa" />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            </>
          ))}
        </Toolbar>
      </PaperBottom>
    </Container>
  );
};

export default Mesas;
