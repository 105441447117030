import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";

import { useTheme } from "./hooks/theme";
import Routes from "./routes";
import { SnackbarProvider } from "notistack";
import { CestaProvider } from "./hooks/cesta";
import { useServiceWorker } from "./hooks/useServiceWorker";
import swal from "sweetalert";

const App: React.FC = () => {
  const { theme } = useTheme();
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  useEffect(() => {
    if (showReload) {
      swal({
        title: `Uma nova versão do app esta disponivel!!\n Deseja atualizar?`,
        buttons: { sim: true, nao: true },
        icon: "warning",
        dangerMode: true,
      }).then((value) => {
        if (value === "sim") {
          reloadPage();
        }
      });
    }
  }, [waitingWorker, showReload, reloadPage]);

  return (
    <ThemeProvider theme={theme}>
      <CestaProvider>
        <SnackbarProvider maxSnack={3}>
          <GlobalStyles />
          <Routes />
        </SnackbarProvider>
      </CestaProvider>
    </ThemeProvider>
  );
};

export default App;
