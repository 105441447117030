import { AxiosRequestConfig } from "axios";
import { useQuery as reactUseQuery } from "react-query";
import api from "../services/api";
import { useAuth } from "./auth";
import { useTheme } from "./theme";

export default function useQuery<T = unknown>(
  name: string,
  url: string,
  options?: AxiosRequestConfig,
  staleTime?: number,
  refetchInterval?: number,
  manual?: boolean
) {
  const { iis, db } = useTheme();

  const header = {
    "server-host": db,
  };

  return reactUseQuery<T | null>(
    name,
    async () => {
      const response = await api(iis, header).get(url, options);
      return response?.data;
    },
    { staleTime, refetchInterval, enabled: !manual }
  );
}
