import { useEffect, useState } from "react";
import useQuery from "../../hooks/useQuery";
import PullToRefresh from "react-simple-pull-to-refresh";
import LayoutMesa from "../../components/LayoutMesa";
import {
  Container,
  PaperTop,
  PaperForm,
  AppTooBar,
  PaperTop2,
  Autocomplete,
  WhiteBorderTextField,
} from "./styled";
import { ContentCard } from "../Home/styles";
import Loading from "../../shared/Loading";
import acimaimg from "../../assets/status/acima.png";
import abaixoimg from "../../assets/status/abaixo.png";

import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import transferItem from "../../shared/transferItem";
import {useTheme} from "../../hooks/theme";

interface iMesaProduto {
  codgrupo: string;
  codpro: string;
  descricao: string;
  enviado: string;
  id: string;
  qnt: string;
  total: string;
  unitario: string;
  datahora: string;
}

interface iMesas {
  mesa: string;
  status: string;
  valor: string;
  servico: string;
  minutos: string;
}

const useMesas = () => {
  const { data } = useQuery<iMesas[]>("listaMesas", "mesas.ashx", {
    params: {
      codpdv: "RES",
    },
  });
  return { data };
};

const useMesaOrigem = (mesa: string) => {
  const { data, isLoading, refetch } = useQuery<iMesaProduto[]>(
    "produtos-MesasOrigem",
    "mesa.ashx",
    {
      params: {
        mesa: mesa,
        codpdv: "RES",
      },
    }
  );
  return { data, isLoading, refetch };
};

const useMesaDestino = (mesa: string) => {
  const { data, isLoading, refetch } = useQuery<iMesaProduto[]>(
    "produtos-MesasDestino",
    "mesa.ashx",
    {
      params: {
        mesa: mesa,
        codpdv: "RES",
      },
    }
  );
  return { data, isLoading, refetch };
};

const Transferencia = () => {
  const [mesaOrigem, setMesaOrigem] = useState<string>("");
  const [mesaDestino, setMesaDestino] = useState<string>("");
  const { data: origem, isLoading, refetch } = useMesaOrigem(mesaOrigem);
  const {
    data: destino,
    isLoading: loaddestino,
    refetch: refetchdestino,
  } = useMesaDestino(mesaDestino);
  const { data: mesas } = useMesas();
  const { iis, db } = useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    if (mesaOrigem && mesaOrigem.length === 5) {
      refetch();
    }
  }, [mesaOrigem, refetch]);

  useEffect(() => {
    if (mesaDestino && mesaDestino.length === 5) {
      refetchdestino();
    }
  }, [mesaDestino, refetchdestino]);

  const handleRefresh = (): Promise<void> => {
    return new Promise((res) => {
      refetch();
      res();
    });
  };

  const handleTransferiItem = async (mesa: string, id: string) => {
    try {
      await transferItem(iis, db, mesa, id);
      refetch();
      refetchdestino();
    } catch (error) {}
  };

  return (
    <Container>
      <AppTooBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            aria-label="voltar"
            color="inherit"
            onClick={() => {
              navigate("/");
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Toolbar>
      </AppTooBar>

      <PaperTop elevation={6}>
        <Autocomplete
          id="clear"
          options={(mesas || []).map((option) => option.mesa)}
          sx={{ width: "100%" }}
          onChange={(event, value) => {
            setMesaOrigem(String(value));
          }}
          renderInput={(params) => (
            <WhiteBorderTextField {...params} label="Mesas Origem" />
          )}
        />
      </PaperTop>

      <PaperForm elevation={12}>
        <PullToRefresh onRefresh={handleRefresh}>
          <ContentCard>
            {/* {error && <p>Algo deu errado : </p>} */}
            {isLoading && <Loading />}
            {!isLoading &&
              origem?.map((item) => (
                <LayoutMesa
                  {...item}
                  component={
                    <img
                      src={abaixoimg}
                      alt="imagem acima"
                      onClick={() => handleTransferiItem(mesaDestino, item.id)}
                    />
                  }
                ></LayoutMesa>
              ))}
          </ContentCard>
        </PullToRefresh>
      </PaperForm>

      <PaperTop2 elevation={6}>
        <Autocomplete
          id="clear"
          options={(mesas || []).map((option) => option.mesa)}
          sx={{ width: "100%" }}
          onChange={(event, value) => {
            setMesaDestino(String(value));
          }}
          renderInput={(params) => (
            <WhiteBorderTextField {...params} label="Mesas Destino" />
          )}
        />
      </PaperTop2>

      <PaperForm elevation={12}>
        <PullToRefresh onRefresh={refetchdestino}>
          <ContentCard>
            {/* {error && <p>Algo deu errado : </p>} */}
            {loaddestino && <Loading />}
            {!loaddestino &&
              destino?.map((item) => (
                <LayoutMesa
                  {...item}
                  component={
                    <img
                      src={acimaimg}
                      alt="imagem acima"
                      onClick={() => handleTransferiItem(mesaOrigem, item.id)}
                    />
                  }
                ></LayoutMesa>
              ))}
          </ContentCard>
        </PullToRefresh>
      </PaperForm>
    </Container>
  );
};

export default Transferencia;
