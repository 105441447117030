import styled, { keyframes } from "styled-components";

interface ITagProps {
  color: string;
}

const animate = keyframes`
  0%{
    transform: translatex(-100px);
    opacity: 0;
  }
  50%{
    opacity: .3;
  }
  100%{
    transform: translatex(0px);
    opacity: 1;
  }
`;

export const Container = styled.li`
  grid-area: CARDAPIO;
  background-color: ${(props) => props.theme.colors.toolbarcolor};
  list-style: none;
  border-radius: 10px;
  height: 75px;

  margin-bottom: 10px;
  padding: 12px 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-right: 10px;
  width: 20%; // Tamanho das celulas dos produtos
  float: left;

  @media (max-width: 1080px) {
    // IPAD
    width: 45%; // Tamanho das celulas dos produtos
    margin-right: 10px;
    /* width: 225px; */
    float: left;
  }
  @media (max-width: 1024px) {
    margin-right: 10px;
    /* width: 215px; */
    float: left;
  }
  @media (max-width: 800px) {
    // MOBILE
    margin-right: 0;
    width: 100%; // Tamanho das celulas dos produtos
    /* width: 100%; */
    float: none;
  }

  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  /* animation: ${animate} 0.5s ease; */

  &:hover {
    opacity: 0.7;
    transform: translateX(10px);
  }

  /* > div {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
  }

  > div span {
    font-size: 17px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 85px;
    @media (max-width: 1080px) {
      // IPAD
      font-size: 14px;
      width: 70px;
    }
    @media (max-width: 800px) {
      //IPHONE
      font-size: 20px;
      width: 135px;
    }
    overflow: hidden;
  }

  > div h3 {
    font-size: 15px;
    font-weight: 500;
    margin-top: 20px;
  }

  > div small {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 12px;
  }

  > img {
    margin: auto;
    border-radius: 10px;
    position: absolute;
    margin: 0 10px 0 10px;
    height: 50px;
    width: 50px;
  }

  > span {
    font-size: 15px;
    border-radius: 80px;
    background: red;
    width: 30px;
    height: 30px;
    position: absolute;
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
  }

  > small {
    width: 30px;
    height: 30px;
    position: absolute;
    align-items: center;
    justify-content: center;
  } */

  /* > div span {
    font-size: 15px;
    border-radius: 80px;
    background: red;
    width: 30px;
    height: 30px;
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
  } */

`;

export const Tag = styled.div<ITagProps>`
  width: 13px;
  height: 60%;
  background-color: ${(props) => props.color};
  position: absolute;
  left: 0;
`;

export const ContainerUL = styled.ul`
  /* list-style: none;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3; */
  height: 70px;

  @media (max-width: 780px) {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  @media (max-width: 450px) {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
`;

export const ContainerSub = styled.li`

> div {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    /* justify-content: space-between; */
    /* padding-left: 1px; */
  }

  > div span {
    font-size: 17px;
    font-weight: 500;
    /* margin-left: 70px; */
    /* border: solid 2px blue; */
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 85px;
    @media (max-width: 1080px) {
      // IPAD
      font-size: 14px;
      width: 70px;
    }
    @media (max-width: 800px) {
      //IPHONE
      font-size: 20px;
      width: 135px;
    }
    /* display: block; */
    overflow: hidden;
  }

  > div h3 {
    font-size: 15px;
    font-weight: 500;
    margin-top: 20px;
    /* margin-left: 70px; */
  }

  > div small {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 12px;
    /* margin-left: 70px; */
  }

  > img {
    margin: auto;
    border-radius: 10px;
    position: absolute;
    margin: 0 10px 0 10px;
    height: 50px;
    width: 50px;
  }

  > span {
    font-size: 15px;
    border-radius: 80px;
    background: red;
    width: 30px;
    height: 30px;
    position: absolute;
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
  }

  > small {
    width: 30px;
    height: 30px;
    position: absolute;
    align-items: center;
    justify-content: center;
  }

  /* > div span {
    font-size: 15px;
    border-radius: 80px;
    background: red;
    width: 30px;
    height: 30px;
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
  } */

`;
