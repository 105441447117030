import React from "react";
import styled from "styled-components";
interface ITagProps {
    color: string;
  }

const Tag = styled.div<ITagProps>`
  width: 13px;
  height: 60%;
  background-color: ${(props) => props.color};
  position: absolute;
  left: 0;
`;

const Container = styled.li`
  background-color: ${(props) => props.theme.colors.toolbarcolor};
  list-style: none;
  border-radius: 10px;
  height: 75px;

  margin-bottom: 10px;
  padding: 12px 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-right: 10px;
  width: 240px;
  float: left;

  @media (max-width: 1080px) {
    // IPAD
    margin-right: 10px;
    /* width: 165px; */
    width: 31.5%;
    float: left;
  }
  @media (max-width: 800px) {
    // IPAD
    margin-right: 10px;
    /* width: 165px; */
    width: 48%;
    float: left;
  }
  @media (max-width: 500px) {
    // MOBILE
    margin-right: 0;
    width: 100%;
    float: none;
  }

  cursor: pointer;
  transition: all 0.3s;
  position: relative;  

  &:hover {
    opacity: 0.7;
    transform: translateX(10px);
  }

  
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 1px;
  }

  > div span {
    font-size: 17px;
    font-weight: 500;
    margin-left: 70px;
    /* border: solid 2px blue; */
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 85px;
    @media (max-width: 1080px) { // IPAD
      font-size: 14px;
      width: 70px;
    }
    @media (max-width: 800px) { //IPHONE
      font-size: 20px;
      width: 135px;
    }
    /* display: block; */
    overflow: hidden;
  }

  > div h3 {
    font-size: 15px;
    font-weight: 500;
    margin-top: 20px;
    margin-left: 70px;
  }

  > div small {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    /* max-width: 70vh; */
    width: 85px;
    /* @media (max-width: 1080px) {
      width: 70px;
    } */
    @media (max-width: 800px) {
      width: 145px;
    }

    font-size: 12px;
    margin-left: 70px;
  }

  > img {
    border-radius: 10px;
    position: absolute;
    margin: 0 10px 0 10px;
    height: 50px;
    width: 50px;
  }

  > div img {
    /* border-radius: 10px; */
    /* position: absolute; */
    /* margin: 0 10px 0 10px; */
    height: 25px;
    width: 25px;
  }
`;

interface iItem {
  icon: any;
  button: any;
  tagColor: string;
  onClick?: () => void;
}

const Item: React.FC<iItem> = ({
  children,
  icon,
  button,
  tagColor,
  onClick,
}) => {
  return (
    <Container>
      <Tag color={tagColor} onClick={onClick} />
      {icon}
      <div onClick={onClick}>{children}</div>
      <div>{button}</div>
    </Container>
  );
};

export default Item;
