import React, { useEffect, useState } from "react";
import useQuery from "../../hooks/useQuery";
import swal from "sweetalert";

import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useSnackbar } from "notistack";

import LayoutCardapio from "../../components/LayoutCardapio";
import LayoutGrupo from "../../components/LayoutGrupo";

import { Container, PaperGrid, AppBar, AppTooBar, PaperBottom } from "./styles";
import { ContentCard } from "../Home/styles";
import { useAuth } from "../../hooks/auth";
import Loading from "../../shared/Loading";
import { useCesta } from "../../hooks/cesta";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import topping from "../../assets/topping.png";
import halfimg from "../../assets/status/half.png";
import halfhalfimg from "../../assets/status/half-half.png";
import quantityimg from "../../assets/status/quantity.png";
import percent70img from "../../assets/status/70percent.png";
import umquartoimg from "../../assets/status/umquarto.png";
import umtercoimg from "../../assets/status/umterco.png";
import api from "../../services/api";
import { useTheme } from "../../hooks/theme";

interface iGrupo {
  codgrupo: string;
  descricao: string;
}

interface iCardapio {
  codpro: string;
  codmod: string;
  descricao: string;
  unitario: number;
  coditem: string;
}

interface iProdutos {
  descricao: string;
  codpro: string;
  unitario: number;
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

interface IItemsCardapio {
  items: iCardapio[];
  mesa: string | undefined;
  loading: boolean;
  toppings: boolean;
  onSearch: (value: string, codSubGrupo: string) => void;
  addItem: (item: iCardapio) => void;
}

interface IItemsGrupo {
  onClickGrupo: (codgrupo: string) => void;
}

interface IItemsTopping {
  items: iCardapio[];
  mesa: string | undefined;
  loading: boolean;
  codgrupo: string;
  onSearch: (value: string, codSubGrupo: string) => void;
  addItem: (item: iCardapio) => void;
}

const useCardapio = (codgrupo: string) => {
  const [items, setItems] = useState<iCardapio[]>([]);

  const { data, isLoading: loadcardapio } = useQuery<iCardapio[]>(
    "lcardapio-" + codgrupo,
    "cardapio.ashx",
    {
      params: {
        codpdv: "RES",
        codgrupo: codgrupo,
      },
    },
    1000 * 60 * 60
  );

  useEffect(() => {
    if (data && data.length > 0) {
      setItems(data);
    }
  }, [data]);

  const onSearch = (value: string, codSubGrupo: string) => {
    if (!data) return;

    if (!data) {
      setItems(data);
    }

    const founds = data.filter((item: { descricao: string }) =>
      item.descricao.includes(value)
    );

    const lcardapio = founds?.filter((item: { coditem: string }) =>
      item.coditem.includes(codSubGrupo)
    );

    setItems(lcardapio);
  };

  return { items, loadcardapio, onSearch };
};

const ItemsCardapio: React.FC<IItemsCardapio> = ({
  items,
  mesa,
  loading,
  toppings,
  onSearch,
  addItem,
}) => {
  return (
    <>
      {loading && <Loading />}
      {!toppings &&
        items?.map((item) => (
          <LayoutCardapio
            key={uuid()}
            mesa={mesa || ""}
            codpro={item.codpro}
            codmod=""
            descricao={item.descricao}
            unitario={item.unitario}
            handleClickSubGrupo={() => onSearch("", item.codpro)}
            onClick={() => addItem(item)}
          />
        ))}
    </>
  );
};

const ItemsGrupo: React.FC<IItemsGrupo> = ({ onClickGrupo }) => {
  const { data, isLoading } = useQuery<iGrupo[]>(
    "lgrupo",
    "grupo.ashx",
    {
      params: {
        codpdv: "RES",
      },
    },
    1000 * 60 * 60
  );

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading &&
        data?.map((item) => (
          <LayoutGrupo
            onClickGrupo={onClickGrupo}
            key={uuid()}
            codgrupo={item.codgrupo}
            descricao={item.descricao}
          />
        ))}
    </>
  );
};

const ItemsToppings: React.FC<IItemsTopping> = ({
  items,
  mesa,
  loading,
  codgrupo,
  onSearch,
  addItem,
}) => {
  const {
    data,
    isLoading,
    refetch: reloadTopping,
  } = useQuery<iCardapio[]>(
    "lToppings",
    "modificadores.ashx",
    {
      params: {
        codgrupo: codgrupo,
        codpdv: "RES",
      },
    },
    1000 * 60 * 60
  );

  useEffect(() => {
    reloadTopping();
  }, [codgrupo]);

  return (
    <>
      {isLoading && <Loading />}
      {loading &&
        data?.map((item) => (
          <LayoutCardapio
            key={uuid()}
            mesa={mesa || ""}
            codpro={item.codpro}
            codmod={item.codpro}
            descricao={item.descricao}
            unitario={item.unitario}
            handleClickSubGrupo={() => onSearch("", item.codpro)}
            onClick={() => addItem(item)}
          />
        ))}
    </>
  );
};

const Cardapio: React.FC = () => {
  const navigate = useNavigate();
  const { mesa } = useParams();
  const [codgrupo, setCodGrupo] = useState<string>(
    localStorage.getItem("@DM7Pedidos:codgrupo") || "001"
  );
  const [codmod, setCodmod] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { items, loadcardapio, onSearch } = useCardapio(codgrupo);
  const {
    quantidade,
    setQuantidade,
    produtos,
    setProdutos,
    setTipoLancamento,
    tipoLancamento,
    addCestaCompra,
    removeCestaCompra,
  } = useCesta();
  const [codpro2, setCodpro2] = useState<iProdutos[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { signOut } = useAuth();

  const handleVoltar = () => navigate("/mesa/" + mesa);
  const { iis, db } = useTheme();

  const handleIncluirProduto = async (
    codpro: string,
    codmod: string,
    unitario: number,
    quantidade: number
  ) => {
    if (unitario === null) {
      // mostrar filtro sub-grupo
      //handleClickSubGrupo();
    } else {
      // Incluir Produto
      if (codmod === "") {
        addCestaCompra(codpro, quantidade);
      }
      const quant = quantidade;
      // setProdutos(1);
      setQuantidade(1);

      const header = { "server-iis": iis, "server-host": db };
      await api(iis, header)
        .get("incProduto.ashx", {
          params: {
            codpdv: "RES",
            codpro: codpro,
            codmod: codmod,
            mesa: mesa,
            usuario: localStorage.getItem("@DM7Pedidos:username"),
            quant: quant,
            unitario: unitario,
          },
        })
        .then((res) => {
          enqueueSnackbar(res.data, {
            variant: "success",
          });
          // console.log("res.data : ", JSON.stringify(res.data));
        })
        .catch((err) => {
          if (err.response.status === 400) {
            signOut();
          } else {
            removeCestaCompra(codpro, 1);
            console.log("err : ", err);
          }
        });
    }
  };

  const onClickGrupo = (codGrupo: string) => {
    setCodmod("");
    setSearchTerm("");
    onSearch("", "");
    localStorage.setItem("@DM7Pedidos:codgrupo", codGrupo);
    setCodGrupo(codGrupo);
  };

  const addItem = (item: iCardapio) => {
    const listaprodutos = [
      ...codpro2,
      {
        codpro: item.codpro,
        codmod: item.codmod,
        descricao: item.descricao,
        unitario: item.unitario,
      },
    ];
    if (codmod !== "") {
      handleIncluirProduto(item.codpro, item.codmod, item.unitario, quantidade);
    } else {
      setCodpro2(listaprodutos);
      if (tipoLancamento === "UNICO") {
        handleIncluirProduto(item.codpro, "", item.unitario, quantidade);
      } else {
        listaprodutos.sort((a, b) => (a.unitario < b.unitario ? 1 : -1));

        setProdutos(produtos - 1);
        if (produtos <= 1) {
          if (tipoLancamento === "MEIO_MEIO") {
            handleIncluirProduto(
              listaprodutos[0].codpro,
              "",
              listaprodutos[0].unitario,
              0.5
            );
            handleIncluirProduto(
              listaprodutos[1].codpro,
              "",
              listaprodutos[0].unitario,
              0.5
            );
          } else {
            if (tipoLancamento === "UM_TERCO") {
              handleIncluirProduto(
                listaprodutos[0].codpro,
                "",
                listaprodutos[0].unitario,
                0.33
              );
              handleIncluirProduto(
                listaprodutos[1].codpro,
                "",
                listaprodutos[0].unitario,
                0.33
              );
              handleIncluirProduto(
                listaprodutos[2].codpro,
                "",
                listaprodutos[0].unitario,
                0.34
              );
            } else {
              handleIncluirProduto(
                listaprodutos[0].codpro,
                "",
                listaprodutos[0].unitario,
                0.25
              );
              handleIncluirProduto(
                listaprodutos[1].codpro,
                "",
                listaprodutos[0].unitario,
                0.25
              );
              handleIncluirProduto(
                listaprodutos[2].codpro,
                "",
                listaprodutos[0].unitario,
                0.25
              );
              handleIncluirProduto(
                listaprodutos[3].codpro,
                "",
                listaprodutos[0].unitario,
                0.25
              );
            }
          }
          setCodpro2([]);
          setProdutos(1);
          setTipoLancamento("UNICO");
        }
      }
    }
    //setCodpro2([]);

    //console.log(produtos);
  };

  return (
    <Container>
      <AppTooBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            aria-label="voltar"
            color="inherit"
            onClick={() => {
              handleVoltar();
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Pesquisar…"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value.toLocaleUpperCase());
                onSearch(e.target.value.toUpperCase(), "");
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Toolbar>
      </AppTooBar>

      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Mesa: <b>{mesa}</b>
          </Typography>

          <IconButton
            size="small"
            aria-label="modificador"
            color="inherit"
            onClick={() => {
              setCodmod("003");
            }}
          >
            <img src={topping} alt="toppings" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <PaperGrid elevation={6}>
        {/* <FormCardapio> */}
        <ContentCard>
          <ItemsCardapio
            items={items}
            mesa={mesa}
            toppings={codmod !== ""}
            loading={loadcardapio}
            onSearch={onSearch}
            addItem={addItem}
          />
          <ItemsToppings
            items={items}
            mesa={mesa}
            codgrupo={codgrupo}
            loading={codmod !== ""}
            onSearch={onSearch}
            addItem={addItem}
          />
        </ContentCard>
        {/* </FormCardapio> */}
        {/* <FormGrupo> */}
        <ContentCard>
          <ItemsGrupo onClickGrupo={onClickGrupo} />
        </ContentCard>
        {/* </FormGrupo> */}
      </PaperGrid>
      <PaperBottom elevation={6}>
        <div>
          <IconButton
            size="medium"
            aria-label="quantidade"
            color="inherit"
            onClick={() => {
              swal({
                title: `Informe a quantidade`,
                content: {
                  element: "input",
                  attributes: {
                    placeholder: "numero de itens",
                  },
                },
                text: "",
                icon: "success",
                dangerMode: true,
              }).then((itens: number) => {
                setProdutos(1);
                setTipoLancamento("UNICO");
                setQuantidade(itens);
              });
            }}
          >
            <img src={quantityimg} alt="imagem status da mesa" />
          </IconButton>
          <IconButton
            size="medium"
            aria-label="meio a meio"
            color="inherit"
            onClick={() => {
              setTipoLancamento("MEIO_MEIO");
              setProdutos(2);
              // handleVoltar();
            }}
          >
            <img src={halfhalfimg} alt="imagem status da mesa" />
          </IconButton>
          <IconButton
            size="medium"
            aria-label="um terco"
            color="inherit"
            onClick={() => {
              setTipoLancamento("UM_TERCO");
              setProdutos(3);
              // handleVoltar();
            }}
          >
            <img src={umtercoimg} alt="imagem status da mesa" />
          </IconButton>
          <IconButton
            size="medium"
            aria-label="um quarto"
            color="inherit"
            onClick={() => {
              setTipoLancamento("UM_QUARTO");
              setProdutos(4);
              // handleVoltar();
            }}
          >
            <img src={umquartoimg} alt="imagem status da mesa" />
          </IconButton>
          <IconButton
            size="medium"
            aria-label="metade"
            color="inherit"
            onClick={() => {
              setTipoLancamento("UNICO");
              setProdutos(1);
              setQuantidade(0.5);
            }}
          >
            <img src={halfimg} alt="imagem status da mesa" />
          </IconButton>
          <IconButton
            size="medium"
            aria-label="70%"
            color="inherit"
            onClick={() => {
              setTipoLancamento("UNICO");
              setProdutos(1);
              setQuantidade(0.7);
            }}
          >
            <img src={percent70img} alt="imagem status da mesa" />
          </IconButton>
        </div>
      </PaperBottom>
    </Container>
  );
};

export default Cardapio;
