import Item from "../shared/Item";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

import Button from "./Button";
import swal from "sweetalert";
import formatCurrency from "../utils/formatCurrency";
import printerimg from "../assets/status/printer2.png";

import * as STATUS from "../utils/status";
import preConta from "../shared/preConta";
import {useTheme} from "../hooks/theme";

export interface iMesas {
  mesa: string;
  status: string;
  valor: string;
  servico: string;
  minutos: string;
}

const Mesa: React.FC<iMesas> = (props) => {
  const { mesa, status, valor, minutos } = props;
  const navigate = useNavigate();
  const { db, iis } = useTheme();

  const handleMesa = () => {
    if (status === STATUS.AGUARDANDO_PAGAMENTO.text) {
      swal({
        title: `Mesa [${mesa}] esta aguardando pagamento`,
        text: "Solicite ao caixa a reabertura da mesa",
        icon: "warning",
        dangerMode: true,
      }).then(() => {});
    } else {
      navigate("/mesa/" + mesa);
    }
  };

  return (
    <Item
      key={uuid()}
      onClick={handleMesa}
      tagColor={
        status === STATUS.LIVRE.text
          ? STATUS.LIVRE.color
          : status === STATUS.CONSUMINDO.text
          ? STATUS.CONSUMINDO.color
          : status === STATUS.BALCAO.text
          ? STATUS.BALCAO.color
          : status === STATUS.ENTREGA.text
          ? STATUS.ENTREGA.color
          : status === STATUS.ENTREGANDO.text
          ? STATUS.ENTREGANDO.color
          : STATUS.AGUARDANDO_PAGAMENTO.color
      }
      icon={
        <img
          onClick={handleMesa}
          src={
            status === STATUS.LIVRE.text
              ? STATUS.LIVRE.img
              : status === STATUS.CONSUMINDO.text
              ? STATUS.CONSUMINDO.img
              : status === STATUS.BALCAO.text
              ? STATUS.BALCAO.img
              : status === STATUS.ENTREGA.text
              ? STATUS.ENTREGA.img
              : status === STATUS.ENTREGANDO.text
              ? STATUS.ENTREGANDO.img
              : STATUS.AGUARDANDO_PAGAMENTO.img
          }
          alt="imagem status da mesa"
        />
      }
      button={
        status !== STATUS.LIVRE.text && (
          <Button
            onClick={() => {
              preConta(iis, db, mesa);
            }}
          >
            <img src={printerimg} alt="imagem status da mesa" />
          </Button>
        )
      }
    >
      <span>{mesa}</span>
      <small>{status}</small>
      <small>{minutos <= "0" ? "" : `${minutos} minutos`}</small>
      <small>
        <b>{valor <= "0" ? "" : formatCurrency(Number(valor))}</b>
      </small>
    </Item>
  );
};

export default Mesa;
