import React, { createContext, useState, useContext } from "react";

interface ICestaContex {
  filtrosubgrupo: string;
  setFiltrosubgrupo(coditem: string): void;
  quantidade: number;
  setQuantidade(quantidade: number): void;
  produtos: number;
  setProdutos(quantidade: number): void;
  tipoLancamento: string;
  setTipoLancamento(tipoLancamento: string): void;
  addCestaCompra(id: string, quantidade: number): void;
  removeCestaCompra(id: string, quantidade: number): void;
  limparCestaCompra(): void;
  getQuantidadeCestaCompra(id: string): number;
}

interface iCestaCompra { 
  id: string;
  quantidade: number;
}

const CestaContext = createContext<ICestaContex>({} as ICestaContex);

const CestaProvider: React.FC = ({ children }) => {
  const [cestaCompra, setCestaCompra] = useState<iCestaCompra[]>([]);
  const [filtrosubgrupo, setFiltrosubgrupo] = useState<string>("");
  const [quantidade, setQuantidade] = useState<number>(1);
  const [produtos, setProdutos] = useState<number>(1);
  const [tipoLancamento, setTipoLancamento] = useState<string>("UNICO");

  const addCestaCompra = (id: string, quantidade: number = 1) => {
    var cestas = cestaCompra.filter(cesta => cesta.id !== id);
    const cestaArray = cestaCompra.find(cesta => cesta.id === id);
    const novaQuatidade = cestaArray ? cestaArray.quantidade + quantidade : quantidade;
    const cesta = { id, quantidade: novaQuatidade };
    setCestaCompra([...cestas, cesta]);
  }

  const removeCestaCompra = (id: string, quantidade: number = 1) => {
    var cestas = cestaCompra.filter(cesta => cesta.id !== id);
    const cestaArray = cestaCompra.find(cesta => cesta.id === id);
    const novaQuatidade = cestaArray ? cestaArray.quantidade - quantidade : quantidade;
    const cesta = { id, quantidade: novaQuatidade };
    setCestaCompra([...cestas, cesta]);
  }

  const limparCestaCompra = () => {
    setCestaCompra([]);
  }

  const getQuantidadeCestaCompra = (id: string) => {
    const cesta = cestaCompra.find((c) => c.id === id);
    return cesta ? cesta.quantidade : 0;
  }

  return (
    <CestaContext.Provider value={{ filtrosubgrupo, setFiltrosubgrupo, quantidade, produtos, setProdutos, setQuantidade, tipoLancamento, setTipoLancamento, addCestaCompra, removeCestaCompra, limparCestaCompra, getQuantidadeCestaCompra }}>
      {children}
    </CestaContext.Provider>
  );
};

function useCesta(): ICestaContex {
  const context = useContext(CestaContext);
  return context;
}

export { CestaProvider, useCesta };
