import styled from "styled-components";
import MuiPaper from "@mui/material/Paper";
import MuiAppBar from '@mui/material/AppBar';

export const Container = styled.div`
  /* height: 100vh; */
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 10px);
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);

  display: flex;
  flex: 1;
  flex-direction: column;
  //justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.colors.toolbarcolor};
  
`;

export const PaperTop = styled(MuiPaper)`
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 95%;
  margin: 0 0 10px 0;
  background: ${(props) => props.theme.colors.backgroundcolor} !important;
`;

export const PaperForm = styled(MuiPaper)`
  width: 95%;
  height: 85%;
  padding: 20px;
  max-height: 100%;
  overflow: auto;

  @media (max-width: 390px) {
    height: 82%;
  }

  @media (max-width: 375px) {
    height: 78%;
  }

  border-radius: 10px !important;
  background-color: ${(props) => props.theme.colors.backgroundcolor} !important;

  > a {
    margin-left: 10px;
    font-size: 12px;
    text-decoration: none;
    color: ${(props) => props.theme.colors.texttoolbarcolor};
  }
`;

export const AppTooBar = styled(MuiAppBar)`
  margin-bottom: 10px;
  background-color: ${(props) => props.theme.colors.backgroundcolor} !important;
  color: ${(props) => props.theme.colors.texttoolbarcolor} !important;
`;

export const PaperBottom = styled(MuiPaper)`
  width: 95%;
  height: 70px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;

  border-radius: 10px !important;	
  background-color: ${(props) => props.theme.colors.backgroundcolor} !important;

  > a {
    margin-left: 10px;
    font-size: 12px;
    text-decoration: none;
    color: ${(props) => props.theme.colors.texttoolbarcolor} !important;
  }

  > div {
    color: ${(props) => props.theme.colors.texttoolbarcolor} !important;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  > div span {
    font-size: 17px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  > div small {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;

    font-size: 12px;
  }

  > div img {
    width: 30px;
    height: 30px;
  }
`;
