import api from "../services/api";

export default async function transferItem(
  iis: string,
  IPv4: string,
  Mesa: any,
  Item: any
) {
  const header = { "server-host": IPv4 };
  await api(iis, header)
    .get(`transferiritem.ashx`, {
      params: {
        origem: Item,
        destino: Mesa,
      },
    })
    .then((res) => {})
    .catch((err) => {
      console.log("err : ", err);
    })
    .finally(() => {});
}
