import consumindoimg from "../assets/status/consumindo.png";
import livreimg from "../assets/status/livre.png";
import balcaoimg from "../assets/status/balcao.png";
import pagamentoimg from "../assets/status/aguardando pagamento.png";
import entregaimg from "../assets/status/entrega.png";
import entregandoimg from "../assets/status/entregando.png";

export const LIVRE = {
  img: livreimg,
  text: "(Livre)",
  color: "#145a32 ",
};
export const CONSUMINDO = {
  img: consumindoimg,
  text: "(Consumindo)",
  color: "#bd1d18 ",
};
export const BALCAO = {
  img: balcaoimg,
  text: "(Balcao)",
  color: "#ba4a00 ",
};
export const ENTREGA = {
  img: entregaimg,
  text: "(Entrega)",
  color: "#a569bd ",
};
export const ENTREGANDO = {
  img: entregandoimg,
  text: "(Entregando)",
  color: "#5b2c6f ",
};
export const AGUARDANDO_PAGAMENTO = {
  img: pagamentoimg,
  text: "(Aguardando Pagamento)",
  color: "#b7950b ",
};
