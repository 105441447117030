import React, { useState, useContext, createContext } from "react";
import api from "../services/api";
import { useTheme } from "./theme";
import swal from "sweetalert";

interface IAuthContex {
  logged: boolean;
  userInfo: string;
  signIn(email: string, password: string): void;
  signOut(sendtohome?: boolean): void;
}

const AuthContext = createContext<IAuthContex>({} as IAuthContex);

const AuthProvider: React.FC = ({ children }) => {
  const { iis, db } = useTheme();

  const [logged, setLogged] = useState<boolean>(() => {
    const isLogged = localStorage.getItem("@DM7Pedidos:logged") || false;
    return isLogged === "true" ? true : false;
  });

  const [userInfo, setUserInfo] = useState<string>("");

  const signIn = async (email: string, password: string) => {
    try {
      // console.log("http : " + http);
      // console.log("iis : " + iis);
      const header = { "server-host": db };
      const res = await api(iis, header).get("login.ashx", {
        params: { usuario: email, senha: password },
      });

      localStorage.setItem("@DM7Pedidos:usuario", JSON.stringify(res.data[0]));

      if (res.data[0] !== undefined && res.data[0]["nome"] !== undefined) {
        setLogged(true);
        localStorage.setItem("@DM7Pedidos:username", email);
        localStorage.setItem("@DM7Pedidos:password", atob(password));
        localStorage.setItem("@DM7Pedidos:logged", JSON.stringify(true));
      } else {
        swal({
          title: "Usuário ou senha invalido!",
          icon: "warning",
          dangerMode: true,
        });
        localStorage.setItem("@DM7Pedidos:logged", JSON.stringify(false));
        setLogged(false);
      }
    } catch (err) {
      console.log("err : ", err);
    }
  };

  const signOut = (sendtohome?: boolean) => {
    localStorage.setItem("@DM7Pedidos:logged", JSON.stringify(false));
    setLogged(false);
    setUserInfo("");
  };

  return (
    <AuthContext.Provider
      value={{
        logged,
        userInfo,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContex {
  const context = useContext(AuthContext);
  return context;
}

export { AuthProvider, useAuth };
