import styled from "styled-components";
import MuiPaper from "@mui/material/Paper";
import MuiAppBar from '@mui/material/AppBar';
import MuiAutocomplete from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";

export const Container = styled.div`
  /* height: 100vh; */
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 10px);
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);

  display: flex;
  flex: 1;
  flex-direction: column;
  //justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.colors.toolbarcolor};
  
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 30px;

  > h2 {
    color: ${(props) => props.theme.colors.white};
    margin-left: 7px;
  }

  > img {
    width: 200px;
    height: 200px;
    border-radius: 7px;
  }
`;

export const FormTitle = styled.h1`
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.white};

  &:after {
    content: "";
    display: block;
    width: 55px;
    border-bottom: 10px solid ${(props) => props.theme.colors.warning};
  }
`;

export const FooterLogin = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  position: fixed;
  bottom: 0;
  font-size: 15px;
  background-color: ${(props) => props.theme.colors.backgroundcolor};
  color: ${(props) => props.theme.colors.texttoolbarcolor};
`;

export const FormCard = styled.main`
  width: 350px;
  height: 450px;
  padding: 30px;

  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.backgroundcolor};
  > a {
    margin-left: 10px;
    font-size: 10px;
    text-decoration: none;
    color: ${(props) => props.theme.colors.texttoolbarcolor};
  }

  @media (max-width: 340px) {
    height: 300px;
    width: 290px;
  }

  /* Galaxy Fold */
  @media (max-width: 280px) {
    height: 300px;
    width: 270px;
  }
`;

export const FormCardTitle = styled.h1`
  margin-bottom: 40px;
  font-size: 30px;
  color: ${(props) => props.theme.colors.texttoolbarcolor};

  &:after {
    content: "";
    display: block;
    width: 55px;
    border-bottom: 10px solid ${(props) => props.theme.colors.warning};
  }

  @media (max-width: 340px) {
    font-size: 23px;
  }

  /* Galaxy Fold */
  @media (max-width: 280px) {
    font-size: 20px;
  }
`;

export const ContentCard = styled.main`
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 15px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.toolbarcolor};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.backgroundcolor};
  }

  @media (max-width: 340px) {
    height: 180px;
  }
`;

export const PaperTop = styled(MuiPaper)`
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 95%;
  margin: 0 0 10px 0;
  background: ${(props) => props.theme.colors.backgroundcolor} !important;
`;

export const PaperTop2 = styled(MuiPaper)`
  margin-top: 10px !important;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 95%;
  margin: 0 0 10px 0;
  background: ${(props) => props.theme.colors.backgroundcolor} !important;
`;

export const PaperForm = styled(MuiPaper)`
  width: 95%;
  height: 36vh;
  padding: 20px;
  max-height: 100%;
  overflow: auto;

  @media (max-width: 1024px) {
    height: 40vh;
  }

  @media (max-width: 926px) {
    height: 31vh;
  }

  @media (max-width: 820px) {
    height: 38vh;
  }

  @media (max-width: 412px) {
    height: 33vh;
  }

  @media (max-width: 414px) {
    height: 35vh;
  }

  @media (max-width: 375px) {
    height: 31vh;
  }

  border-radius: 10px !important;
  background-color: ${(props) => props.theme.colors.backgroundcolor} !important;

  > a {
    margin-left: 10px;
    font-size: 12px;
    text-decoration: none;
    color: ${(props) => props.theme.colors.texttoolbarcolor};
  }
`;

export const AppTooBar = styled(MuiAppBar)`
  margin-bottom: 10px;
  background-color: ${(props) => props.theme.colors.backgroundcolor} !important;
  color: ${(props) => props.theme.colors.texttoolbarcolor} !important;
`;

export const Autocomplete = styled(MuiAutocomplete)`
  margin: 5px 0px 3px 0;
  background: ${(props) => props.theme.colors.backgroundcolor} !important;
`;


export const WhiteBorderTextField = styled(TextField)`
  & label.Mui-focused {
    color: white;
  }
  & .MuiOutlinedInput-root {
    border: 0px;
    &.Mui-focused fieldset {
      border-color: yellow;
    }
  }
`;