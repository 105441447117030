import React from "react";
import { useAuth } from "../../hooks/auth";

import { Container, Tag } from "./styles";
import cancelarimg from "../../assets/status/cancelar.png";
import confirmadoimg from "../../assets/status/confirmado.png";

import swal from "sweetalert";
import formatCurrency from "../../utils/formatCurrency";
import api from "../../services/api";
import { useSnackbar } from "notistack";
import { useCesta } from "../../hooks/cesta";
import moment from "moment";
import { useTheme } from "../../hooks/theme";

interface iListaMesa {
  codgrupo: string;
  codpro: string;
  descricao: string;
  enviado: string;
  id: string;
  qnt: string;
  total: string;
  unitario: string;
  datahora: string;
  component?: any | null;
  onTranferiItemClick?: () => void;
}

const LayoutMesa: React.FC<iListaMesa> = ({
  children,
  codgrupo,
  codpro,
  descricao,
  enviado,
  id,
  qnt,
  total,
  unitario,
  datahora,
  component,
  onTranferiItemClick,
}) => {
  const { removeCestaCompra } = useCesta();
  const possocancelar = JSON.parse(
    localStorage.getItem("@DM7Pedidos:usuario") || ""
  )["podecancelar"];
  const { signOut } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { iis, db } = useTheme();

  const handleCancelar = async () => {
    removeCestaCompra(codpro, 1);

    const header = { "server-host": db };
    await api(iis, header)
      .get("cancelaritem.ashx", {
        params: {
          host: localStorage.getItem("@DM7Pedidos:IPv4"),
          id: id,
        },
      })
      .then((res) => {
        enqueueSnackbar(`Item [${descricao}] cancelado!`, {
          variant: "warning",
        });
      })
      .catch((err) => {
        if (err.response.status === 400) {
          signOut();
        } else {
          console.log("err : ", err);
        }
      });
  };

  return (
    <Container>
      <Tag color={enviado === "true" ? "#145a32" : "#bc1a0a"} />

      {component && component}
      {children}
      {/* 
      {onTranferiItemClick && (
        <button style={{padding: '10px', background: 'green', color: 'white'}} onClick={onTranferiItemClick}>Transferir</button>
      )}

      {onApagarItemClick && (
        <button style={{padding: '10px', background: 'green', color: 'white'}} onClick={onApagarItemClick}>Apagar</button>
      )} */}

      {!component && (
        <img
          src={enviado === "true" ? confirmadoimg : cancelarimg}
          alt="imagem status do item"
          onClick={() => {
            if (possocancelar === "N" && enviado === "true") {
              swal({
                title: "Você não tem permissão para cancelar item em andamento",
                icon: "warning",
                dangerMode: true,
              });
            } else {
              swal({
                title: `Deseja cancelar : [${descricao}]?`,
                buttons: { sim: true, nao: true },
                icon: "warning",
                dangerMode: true,
              }).then((value) => {
                if (value === "sim") {
                  handleCancelar();
                }
              });
            }
          }}
        />
      )}
      <div>
        <small>
          {codpro} - {descricao}
        </small>
        <small>
          <b>{unitario <= "0" ? "" : formatCurrency(Number(unitario))}</b> x{" "}
          <b>{qnt <= "0" ? "" : formatCurrency(Number(qnt))}</b> ={" "}
          <b>{total <= "0" ? "" : formatCurrency(Number(total))}</b>
        </small>
        <small>{moment(datahora).format("DD/MM : hh:mm")}</small>
      </div>
    </Container>
  );
};

export default LayoutMesa;
