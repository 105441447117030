import styled from "styled-components";
import MuiPaper from "@mui/material/Paper";
import MuiAppBar from "@mui/material/AppBar";

export const Container = styled.div`
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 10px);
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);

  display: flex;
  flex: 1;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  position: relative;

  background-color: ${(props) => props.theme.colors.toolbarcolor};
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 30px;

  > h2 {
    color: ${(props) => props.theme.colors.white};
    margin-left: 7px;
  }

  > img {
    width: 200px;
    height: 200px;
    border-radius: 7px;
  }
`;

export const FormTitle = styled.h1`
  margin-bottom: 20px;
  color: ${(props) => props.theme.colors.white};

  &:after {
    content: "";
    display: block;
    width: 55px;
    border-bottom: 10px solid ${(props) => props.theme.colors.warning};
  }
`;

export const FooterLogin = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  position: fixed;
  bottom: 0;
  font-size: 15px;
  background-color: ${(props) => props.theme.colors.backgroundcolor};
  color: ${(props) => props.theme.colors.texttoolbarcolor};
`;

export const FormCard = styled.main`
  width: 350px;
  height: 450px;
  padding: 30px;

  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.backgroundcolor};
  > a {
    margin-left: 10px;
    font-size: 10px;
    text-decoration: none;
    color: ${(props) => props.theme.colors.texttoolbarcolor};
  }

  @media (max-width: 340px) {
    height: 300px;
    width: 290px;
  }

  /* Galaxy Fold */
  @media (max-width: 280px) {
    height: 300px;
    width: 270px;
  }
`;

export const FormCardTitle = styled.h1`
  margin-bottom: 40px;
  font-size: 30px;
  color: ${(props) => props.theme.colors.texttoolbarcolor};

  &:after {
    content: "";
    display: block;
    width: 55px;
    border-bottom: 10px solid ${(props) => props.theme.colors.warning};
  }

  @media (max-width: 340px) {
    font-size: 23px;
  }

  /* Galaxy Fold */
  @media (max-width: 280px) {
    font-size: 20px;
  }
`;

export const ContentCard = styled.main`
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 15px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.toolbarcolor};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.backgroundcolor};
  }

  @media (max-width: 340px) {
    height: 180px;
  }
`;

export const FormBottom = styled.div`
  width: 95%;
  height: 70px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;

  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.backgroundcolor};

  > a {
    margin-left: 10px;
    font-size: 12px;
    text-decoration: none;
    color: ${(props) => props.theme.colors.texttoolbarcolor};
  }

  > div {
    color: ${(props) => props.theme.colors.texttoolbarcolor};
    margin-left: 10px;
  }

  > div span {
    font-size: 17px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  > div small {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;

    font-size: 12px;
  }
`;

export const PaperGrid = styled(MuiPaper)`
  display: grid;
  background-color: ${(props) => props.theme.colors.backgroundcolor} !important;
  color: ${(props) => props.theme.colors.texttoolbarcolor} !important;
  width: 95%;
  padding: 20px;

  grid-template-areas: "CARDAPIO" "GRUPO";

  grid-template-columns: 4fr 1fr;
  /* grid-template-rows: 100%; */
  height: 73%;

  @media (max-width: 812px) {
    height: 72%;
  }

  border-radius: 10px !important;

  /* height: 100vh;
  min-height: 315px; */

  @media (max-width: 1024px) {
    grid-template-columns: 2fr 1fr;
  }
`;

export const AppBar = styled(MuiAppBar)`
  width: 95% !important;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.backgroundcolor} !important;
  color: ${(props) => props.theme.colors.texttoolbarcolor} !important;

  > div img {
    margin-top: -10px;
    width: 40px;
    height: 40px;
  }
`;

export const AppTooBar = styled(MuiAppBar)`
  margin-bottom: 10px;
  background-color: ${(props) => props.theme.colors.backgroundcolor} !important;
  color: ${(props) => props.theme.colors.texttoolbarcolor} !important;
`;

export const PaperBottom = styled(MuiPaper)`
  width: 95%;
  height: 70px;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;

  border-radius: 10px !important;
  background-color: ${(props) => props.theme.colors.backgroundcolor} !important;

  > a {
    margin-left: 10px;
    font-size: 12px;
    text-decoration: none;
    color: ${(props) => props.theme.colors.texttoolbarcolor} !important;
  }

  > div {
    color: ${(props) => props.theme.colors.texttoolbarcolor} !important;
    position: relative;
    top: 60%;
    transform: translateY(-50%);
  }

  > div span {
    font-size: 17px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  > div small {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;

    font-size: 12px;
  }

  > div img {
    margin-top: -10px;
    width: 30px;
    height: 30px;
  }
`;
