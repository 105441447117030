const light = {
  title: "light",
  colors: {
    toolbarcolor: "#e5e8e8 ",
    backgroundcolor: "#FFFFFF",
    texttoolbarcolor: "#000",
    textbackgroundcolor: "#000",
    buttoncolor: "#fdb900",

    white: "#FFF",
    black: "#000",
    gray: "#BFBFBF",

    success: "#4E41F0",
    info: "#F7931B",
    warning: "#E44C4E",
  },
};

export default light;