import axios, { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

const DEFAULT_HEADERS = {
  Accept: 'application/vnd.dm7+json; version=p.4',
  'X-Request-GUID': uuid(),
  // 'Access-Control-Allow-Origin': 'http://192.168.50.196/SPRPhone-ws/',
  // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
  // 'Access-Control-Allow-Headers': 'Content-Type',
};

const api = (iis: any, header: any = {}, ...options : any): AxiosInstance => {
  // console.log(httpsecure, iis);
  // console.log(iis);
  return axios.create({
    baseURL: iis !== "" ? "http://" + iis : process.env.REACT_APP_API_BASE,
    headers: {...DEFAULT_HEADERS, ...header } ,
    ...options
  })
};

export default api;