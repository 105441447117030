import React from "react";
import { Container } from "./styles";

interface iListaMesa {
  codgrupo: string;
  descricao: string;
  onClickGrupo: (codgrupo: string) => void;
}

const LayoutGrupo: React.FC<iListaMesa> = ({
  codgrupo,
  descricao,
  onClickGrupo,
}) => {
  return (
    <Container onClick={() => onClickGrupo(codgrupo)}>
      <div>
        <small>{descricao}</small>
      </div>
    </Container>
  );
};

export default LayoutGrupo;
