import swal from "sweetalert";
import api from "../services/api";

export default function preConta(IPv4http: string, IPv4: string, Mesa: any) {
  swal({
    title: `Mesa [${Mesa}] sera impressa`,
    content: {
      element: "input",
      attributes: {
        placeholder: "numero de pessoas",
      },
    },
    text: "Informe o numero da pessoas",
    icon: "success",
    dangerMode: true,
  }).then(async (pessoas: any) => {
    const header = { "server-host": IPv4, "server-iis": IPv4http };

    await api(IPv4http, header).get("solicitarconta.ashx", {
      params: {
        usuario: localStorage.getItem("@DM7Pedidos:username"),
        codpdv: "RES",
        mesa: Mesa,
        pessoas: pessoas === "" ? 1 : isNaN(pessoas) ? 1 : pessoas,
      },
    });
  });
}
