import React, { useState, useEffect } from "react";
import logo from "../../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import swal from "sweetalert";

import Input from "../../components/Input";
import Button from "../../components/Button";

import { useAuth } from "../../hooks/auth";
import { useTheme } from "../../hooks/theme";

import { Container, Logo, FormTitle, FooterLogin, PaperForm } from "./styles";
import Tab from "@mui/material/Tab";

import PhonelinkSetupIcon from "@mui/icons-material/PhonelinkSetup";
import ColorLensIcon from "@mui/icons-material/ColorLens";

import LoginIcon from "@mui/icons-material/Login";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import { CompactPicker } from "react-color"; // http://casesandberg.github.io/react-color/

const Home: React.FC = () => {
  const navigate = useNavigate();

  const { signIn, logged } = useAuth();
  const {
    saveDB_IP,
    nomeRestaurante,
    setNomeRestaurante,
    saveIIS_IP,
    theme,
  } = useTheme();

  useEffect(() => {
    if (logged) {
      navigate("/mesas");
    }
  }, [logged, navigate]);

  const [email, setEmail] = useState<string>(() => {
    return localStorage.getItem("@DM7Pedidos:username") || "";
  });

  const [password, setPassword] = useState<string>(
    btoa(localStorage.getItem("@DM7Pedidos:password") || "")
  );

  const [toolbarcolor, setToolbarcolor] = useState("#f1c40f");
  const [btntoolbarcolor, setBtntoolbarcolor] = useState<boolean>(false);

  const [backgroundcolor, setBackgroundcolor] = useState("#ca6f1e");
  const [btnbackgroundcolor, setBtnbackgroundcolor] = useState<boolean>(false);

  const [texttoolbarcolor, setTexttoolbarcolor] = useState("#fcfeff");
  const [btntexttoolbarcolor, setBtntexttoolbarcolor] =
    useState<boolean>(false);

  const [textbackgroundcolor, setTextbackgroundcolor] = useState("#02271a");
  const [btntextbackgroundcolor, setBtntextbackgroundcolor] =
    useState<boolean>(false);

  const [buttoncolor, setButtoncolor] = useState("#a93226");
  const [btnbuttoncolor, setBtnbuttoncolor] = useState<boolean>(false);

  const [isAgreed, setIsAgreed] = useState<boolean>(true);

  const [ipdb, setIpdb] = useState<string>(
    localStorage.getItem("@DM7Pedidos:ipdb") || "192.168.50.124"
  );
  const [ipiis, setIpiis] = useState<string>(
    localStorage.getItem("@DM7Pedidos:ipiis") || "sprpedidos.dm7sistemas.com.br"
  );

  useEffect(() => {
    getIPv4();
  }, []);

  const getIPv4 = async () => {
    try {
      // const resIp = await axios.get("https://geolocation-db.com/json/");
      // saveIp(resIp.data.IPv4);

      saveDB_IP(ipdb);
      saveIIS_IP(ipiis);

      await api(ipiis, { "server-host": ipdb })
        .get("restaurante.ashx")
        .then((res: { data: { [x: string]: string }[] }) => {
          setNomeRestaurante(res.data[0]["nome"]);
        });
    } catch (err) {
      swal({
        title: "Log\n" + err,
        icon: "success",
        dangerMode: true,
      });
      console.log(err);
    }
  };

  const [value, setValue] = React.useState("1");

  const handleCloseBtns = () => {
    setBtntoolbarcolor(false);
    setBtnbackgroundcolor(false);
    setBtntexttoolbarcolor(false);
    setBtntextbackgroundcolor(false);
    setBtnbuttoncolor(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const isAgreedhandle = () => {
    return setIsAgreed(!isAgreed);
  };

  return (
    <Container>
      <Logo>
        <img src={logo} alt="Company Logo" />
      </Logo>
      <PaperForm elevation={12}>
        <FormTitle>
          <h1>
            Login - <small>{nomeRestaurante}</small>
          </h1>
        </FormTitle>
        <TabContext value={value}>
          <TabList onChange={handleChange} centered>
            <Tab icon={<LoginIcon />} value="1"></Tab>
            <Tab icon={<PhonelinkSetupIcon />} value="2" />
            <Tab icon={<ColorLensIcon />} value="3" />
          </TabList>
          <TabPanel value="1">
            <Input
              type="email"
              required
              value={email}
              placeholder="usuario"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Input
              type="password"
              required
              value={password}
              placeholder="senha"
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              disabled={!isAgreed}
              onClick={() => {
                signIn(email, password);
              }}
            >
              Login
            </Button>

            <input
              name="isAccept"
              type="checkbox"
              checked={isAgreed}
              onChange={() => {
                isAgreedhandle();
              }}
            />
            <a
              style={{
                marginLeft: "10px",
                fontSize: "10px",
                textDecoration: "none",
                color: theme.colors.texttoolbarcolor,
              }}
              href="https://www.dm7sistemas.com.br/terms-of-use/"
              target="_blank"
              rel="noreferrer"
            >
              Eu concordo com os termos de uso do aplicativo
            </a>
          </TabPanel>
          <TabPanel value="2">
            <Input
              type="text"
              required
              value={ipiis}
              placeholder="ip iis"
              onChange={(e) => setIpiis(e.target.value)}
            />
            <Input
              type="text"
              required
              value={ipdb}
              placeholder="ip banco de dados"
              onChange={(e) => setIpdb(e.target.value)}
            />

            <Button
              onClick={async () => {
                saveDB_IP(ipdb);
                saveIIS_IP(ipiis);

                await api(ipiis, { "server-host": ipdb })
                  .get("restaurante.ashx")
                  .then((res: { data: { [x: string]: string }[] }) => {
                    setNomeRestaurante(res.data[0]["nome"]);
                    if (res.data[0]["nome"] !== "") {
                      swal({
                        title: "Configuração salva com sucesso!\n",
                        icon: "success",
                        dangerMode: true,
                      });
                    }
                  })
                  .catch((err: string) => {
                    setNomeRestaurante("");
                    swal({
                      title: "Alguma coisa esta errada!\n" + err + "\n",
                      icon: "warning",
                      dangerMode: true,
                    });
                  });
              }}
            >
              Salvar
            </Button>
          </TabPanel>
          <TabPanel value="3">
            <Button
              onClick={() => {
                handleCloseBtns();
                setBtntoolbarcolor(!btntoolbarcolor);
              }}
            >
              Barra de tarefas
            </Button>

            {btntoolbarcolor && (
              <CompactPicker
                color={toolbarcolor}
                onChange={(color) => {
                  theme.colors.toolbarcolor = color.hex;
                  setToolbarcolor(color.hex);
                }}
              />
            )}

            <Button
              onClick={() => {
                handleCloseBtns();
                setBtnbackgroundcolor(!btnbackgroundcolor);
              }}
            >
              Cor do fundo
            </Button>

            {btnbackgroundcolor && (
              <CompactPicker
                color={backgroundcolor}
                onChange={(color) => {
                  theme.colors.backgroundcolor = color.hex;
                  setBackgroundcolor(color.hex);
                }}
              />
            )}

            <Button
              onClick={() => {
                handleCloseBtns();
                setBtntexttoolbarcolor(!btntexttoolbarcolor);
              }}
            >
              Cor do texto
            </Button>

            {btntexttoolbarcolor && (
              <CompactPicker
                color={texttoolbarcolor}
                onChange={(color) => {
                  theme.colors.texttoolbarcolor = color.hex;
                  setTexttoolbarcolor(color.hex);
                }}
              />
            )}

            <Button
              onClick={() => {
                handleCloseBtns();
                setBtntextbackgroundcolor(!btntextbackgroundcolor);
              }}
            >
              Cor do texto de fundo
            </Button>

            {btntextbackgroundcolor && (
              <CompactPicker
                color={textbackgroundcolor}
                onChange={(color) => {
                  theme.colors.textbackgroundcolor = color.hex;
                  setTextbackgroundcolor(color.hex);
                }}
              />
            )}

            <Button
              onClick={() => {
                handleCloseBtns();
                setBtnbuttoncolor(!btnbuttoncolor);
              }}
            >
              Cor dos botoes
            </Button>

            {btnbuttoncolor && (
              <CompactPicker
                color={buttoncolor}
                onChange={(color) => {
                  theme.colors.buttoncolor = color.hex;
                  setButtoncolor(color.hex);
                }}
              />
            )}

            <Button
              onClick={() => {
                localStorage.setItem(
                  "@DM7Pedidos:theme",
                  JSON.stringify(theme)
                );
              }}
            >
              Salvar Cores
            </Button>
          </TabPanel>
        </TabContext>
      </PaperForm>
      <FooterLogin>
        <p style={{ textAlign: "center", width: "95%" }}>
          © 2023 DM7 Sistemas. Todos os direitos reservados. Versao: Mar/2023B
        </p>
      </FooterLogin>
    </Container>
  );
};

export default Home;
