import React, { createContext, useState, useContext } from "react";

import DM7 from "../styles/themes/light";

interface IThemeContex {
  toggleTheme(): void;
  theme: ITheme;
  db: string;
  saveDB_IP(ip: string): void;
  iis: string;
  saveIIS_IP(ip: string): void;
  nomeRestaurante: string;
  setNomeRestaurante(nomeRestaurante: string): void;
}

interface ITheme {
  title: string;
  colors: {
    toolbarcolor: string;
    backgroundcolor: string;
    texttoolbarcolor: string;
    textbackgroundcolor: string;
    buttoncolor: string;

    white: string;
    black: string;
    gray: string;

    success: string;
    info: string;
    warning: string;
  };
}

const ThemeContext = createContext<IThemeContex>({} as IThemeContex);

const ThemeProvider: React.FC = ({ children }) => {
  const [db, setIp] = useState<string>(localStorage.getItem("@DM7Pedidos:ip") ?? "");
  const [iis, setIpiis] = useState<string>(localStorage.getItem("@DM7Pedidos:ipiis") ?? "");
  const [nomeRestaurante, setNomeRestaurante] = useState<string>("");

  const [theme, setTheme] = useState(() => {
    const themeSaved = localStorage.getItem("@DM7Pedidos:theme");
    if (themeSaved) {
      return JSON.parse(themeSaved);
    } else {
      return DM7;
    }
  });

  const toggleTheme = () => {
    const themeSaved: [ITheme] = JSON.parse(
      localStorage.getItem("@DM7Pedidos:theme") || "{}"
    );
    setTheme(themeSaved);
  };

  const saveDB_IP = (ip: string) => {
    localStorage.setItem("@DM7Pedidos:ipdb", ip);
    setIp(ip);
  }

  const saveIIS_IP = (ip: string) => {
    localStorage.setItem("@DM7Pedidos:ipiis", ip);
    setIpiis(ip);
  }

  return (
    <ThemeContext.Provider value={{ toggleTheme, theme, db, saveDB_IP, iis, saveIIS_IP, nomeRestaurante, setNomeRestaurante }}>
      {children}
    </ThemeContext.Provider>
  );
};

function useTheme(): IThemeContex {
  const context = useContext(ThemeContext);
  return context;
}

export { ThemeProvider, useTheme };
