import React from "react";

import { Container, ContainerSub } from "./styles";

import formatCurrency from "../../utils/formatCurrency";
import { useCesta } from "../../hooks/cesta";
import GroupWorkIcon from "@mui/icons-material/GroupWork";

interface iListaMesa {
  mesa: string;
  codpro: string;
  codmod: string;
  descricao: string;
  unitario: number;
  handleClickSubGrupo: () => void;
  onClick: () => void;
}

const LayoutCardapio: React.FC<iListaMesa> = ({
  mesa,
  codpro,
  descricao,
  unitario,
  handleClickSubGrupo,
  onClick,
}) => {
  const {
    getQuantidadeCestaCompra,
  } = useCesta();

  const total = getQuantidadeCestaCompra(codpro);

  return (
    <Container onClick={onClick}>
      {/* <Tag color={enviado === "true" ? "#145a32" : "#bc1a0a"} />
      <img
        src={enviado === "true" ? confirmadoimg : cancelarimg}
        alt="imagem status do item"
        onClick={() => {
          if ((possocancelar === "N") && (enviado === "true")) {
            swal({
              title: "Você não tem permissão para cancelar item em andamento",
              icon: "warning",
              dangerMode: true,
            });
          } else {
            swal({
              title: "Deseja cancelar este item?",
              buttons: { sim: true, nao: true },
              icon: "warning",
              dangerMode: true,
            }).then((value) => {
              if (value === "sim") {
                console.log("cancelar");
              }
            });
          }
        }}
      /> */}
      <ContainerSub>
        {unitario === null ? (
          <small>
            <GroupWorkIcon />
          </small>
        ) : (
          <span>{total > 0 ? total : ""}</span>
        )}

        <div>
          <small>{descricao}</small>
          <small>
            <b>{unitario <= 0 ? "" : formatCurrency(Number(unitario))}</b>
          </small>
        </div>
      </ContainerSub>
      {/* <IconButton
        onClick={(e) => {
          handleIncluirProdutoQtd(codpro, descricao);
        }}
        size="large"
        aria-label="voltar"
        color="inherit"
      >
        <ProductionQuantityLimitsIcon />
      </IconButton> */}

      {/* <div>
        <Button
          onClick={() => {
            console.log("como lancar o item");
          }}
        >
          <img src={printerimg} alt="imagem status da mesa" width={15} />
        </Button>
      </div> */}
      {/* <div>
      <span>{total > 0 ? total: ""}</span>
      </div> */}
    </Container>
  );
};

export default LayoutCardapio;
